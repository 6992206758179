<template>
  <div ref="carouselItem">
    <slot />
  </div>
</template>

<script setup lang="ts">
function getTouch(event: TouchEvent) {
  return event.touches[0]
}
const xDown = ref<Number | null>()
const yDown = ref<Number | null>()
const carouselItem = ref<HTMLDivElement>()

onMounted(() => {
  carouselItem.value?.addEventListener('touchstart', onTouchStart, {
    passive: true,
  })
  carouselItem.value?.addEventListener('touchmove', onTouchMove, {
    passive: true,
  })
})
onBeforeUnmount(() => {
  carouselItem.value?.removeEventListener('touchstart', onTouchStart)
  carouselItem.value?.removeEventListener('touchmove', onTouchMove)
})
const emit = defineEmits<{ (swipeType: string): void }>()
const notifySwipe = (swipeType: string) => {
  emit(swipeType)
  xDown.value = null
  yDown.value = null
}
const onTouchStart = (event: TouchEvent) => {
  const firstTouch = getTouch(event)
  xDown.value = firstTouch.clientX
  yDown.value = firstTouch.clientY
}

const onTouchMove = (event: TouchEvent) => {
  if (!xDown.value || !yDown.value) {
    return
  }
  const touch = getTouch(event)
  const xDiff = Number(xDown.value) - touch.clientX
  const yDiff = Number(yDown.value) - touch.clientY

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    xDiff > 0 ? notifySwipe('swipeLeft') : notifySwipe('swipeRight')
  }
}
</script>
