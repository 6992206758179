<template>
  <CarouselSwipeItem
    class="carousel-item"
    :style="{ ...itemWidth, marginRight: itemMarginRight }"
    @swipe-left="notifySwipe('swipeLeft')"
    @swipe-right="notifySwipe('swipeRight')"
  >
    <StoryblokComponent
      v-for="childBlok in blok.content"
      :key="childBlok._uid"
      :blok="childBlok"
    />
  </CarouselSwipeItem>
</template>

<script lang="ts" setup>
import type { SbCarouselItemStoryblok } from '@/types'

interface Props {
  blok: SbCarouselItemStoryblok
}

type emitsType = 'swipeLeft' | 'swipeRight'

const props = defineProps<Props>()
const emit = defineEmits<{ (e: emitsType): void }>()

const itemWidth = computed(() => {
  const defaultWidth = 400

  return {
    '--mobile-width': props.blok.full_width_on_mobile
      ? '100%'
      : `${props.blok?.width_mobile || defaultWidth}px`,
    '--tablet-width': `${props.blok?.width_tablet || defaultWidth}px`,
    '--desktop-width': `${props.blok?.width_desktop || defaultWidth}px`,
  }
})

const itemMarginRight = computed(() => {
  return `${props.blok?.margin_right || 0}px`
})

const notifySwipe = (swipeType: emitsType) => {
  emit(swipeType)
}
</script>

<style lang="scss" scoped>
.carousel-item {
  --mobile-width: 0;
  --tablet-width: 0;
  --desktop-width: 0;

  flex-shrink: 0;
  width: var(--mobile-width);

  @media (min-width: $breakpoint-md) {
    width: var(--tablet-width);
  }

  @media (min-width: $breakpoint-lg) {
    width: var(--desktop-width);
  }
}
</style>
